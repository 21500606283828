<template>

  <div>
    <div v-if="samplingId.length === 0" class="scan-area">
      <div class="scan-line">
        <!-- <div class="scan-line"></div> -->
      </div>
      <!-- 增加同层播放调用，muted 静音 -->
      <!-- 1、playsinline：IOS浏览器支持小窗内播放
*2、x5-playsinline：h5视频微信禁止全屏
*3、webkit-playsinline：微信浏览器支持小窗内播放 -->
      <video ref="videoElement" autoplay playsinline webkit-playsinline x5-playsinline x5-video-player-type="h5" muted
             loop></video>
      <div class="hint-msg">请将样品条形码放入扫描区域内</div>
    </div>

  </div>
</template>

<script>

import QrScanner from 'qrcode-reader';

export default {
  name: "SampleCamera",
  components: {

  },
  data() {
    return {
      samplingId: ''
    }
  },
  mounted() {
    this.startCamera();
    this.initQRDecode();
  },
  methods: {
    // 判断是否为移动端
    isMobile() {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
      return flag;
    },
    async startCamera() {
      try {
        // 强制后置摄像头
        const constraints = { audio: true,video: { facingMode: { exact: "environment" } ,width: 1980, height: 1080},  };
        const videoElement = this.$refs.videoElement;
        const stream = await navigator.mediaDevices.getUserMedia(constraints);

        videoElement.srcObject = stream;
        // 对于不能自动播放，这里直接播放
        if (videoElement && this.isMobile()) {
          console.log('in modible,', navigator.userAgent);
          //防止看到controls（播放按钮）
          videoElement.style.opacity = '0';
          //开始播放视频
          videoElement.play();
          //显示controls（播放按钮）
          videoElement.setAttribute('controls', 'controls');

          this.$nextTick(() => {   //在nextTick里可以拿到更新后的值
            setTimeout(() => {
              videoElement.removeAttribute('controls');
              videoElement.style.opacity = '1';
            }, 0)
          })
        }
      } catch (error) {
        console.log("无法打开摄像头");
        console.error("Failed to access camera:", error);
      }
    },
    initQRDecode() {
      const video = this.$refs.videoElement;
      const scanner = new QrScanner(video);
      const videoElement = this.$refs.videoElement;

      scanner.addEventListener('scan', (content) => {
        console.log('扫描结果:', content);
        alert(content)
        scanner.stop(); // 扫描完成后停止
        videoElement.stop()
      });

      scanner.start(); // 开始扫描
    },
  }
}
</script>

<style scoped>
.scan-area {
  height: calc(100vh - 50px);
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.scan-line {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  height: 3px;
  /* background-color: #57bf9a; */
  background-image: linear-gradient(to right, transparent, #57bf9a, transparent);
  animation: scanAnimation 2s infinite;
}

.hint-msg {
  position: absolute;
  bottom: 50px;
  width: 100%;
  color: #ffffff;
  text-align: center;
}

@keyframes scanAnimation {
  0% {
    top: 40%;
  }
  100% {
    top: 60%
  }
}
</style>